<template>
	<div class="page">
		<!-- 搜索栏 -->
		<div class="searchView">
			<el-input v-model="keyword" placeholder="请输入关键字" prefix-icon="iconfont el-icon-search"
				style="width: 200px;margin-right: 20px;" @keyup.enter.native="handelSearchClick"></el-input>
			<el-button type="primary" style="margin-right: 32px;" @click="handelSearchClick">查询</el-button>
			<div class="search">
				<div class="searchTitle">活动日期:</div>
				<el-date-picker v-model="dateValue" type="daterange" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" style="width: 260px;" @change="handelSearchClick">
				</el-date-picker>
			</div>
			<div class="search">
				<div class="searchTitle">活动状态:</div>
				<el-select v-model="statusValue" placeholder="请选择" style="width: 143px;" @change="handelSearchClick">
					<el-option v-for="item in statuss" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="btnView">
				<el-button @click="handleResetClick">重置</el-button>
				<el-button type="primary" v-if="$buttonAuthority('huodong-huodongList-add')" @click="handleAddClick">新建活动</el-button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="view">
			<div class="tableView">
				<el-table :data="tableData">
					<el-table-column prop="name" label="活动名称">
					</el-table-column>
					<el-table-column prop="activityStatus" label="状态">
						<template slot-scope="scope">
							<span v-if="scope.row.activityStatus == 1">未进行</span>
							<span v-if="scope.row.activityStatus == 2">进行中</span>
							<span v-if="scope.row.activityStatus == 3">已结束</span>
							<span v-if="scope.row.activityStatus == 4">已停用</span>
						</template>
					</el-table-column>
					<el-table-column prop="remark" label="活动说明">
					</el-table-column>
					<el-table-column prop="beginTime" label="开始日期" :key="5" align="center">
						<template slot-scope="scope">
							<span>{{ $public.FTime(scope.row.beginTime) }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="endTime" label="截止日期" :key="6" align="center">
						<template slot-scope="scope">
							<span>{{ $public.FTime(scope.row.endTime, 'YYYY-MM-DD HH:mm:ss') }}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="200">
						<template slot-scope="scope">
							<el-button type="text" size="small" 
								v-if="scope.row.activityStatus == 2&&$buttonAuthority('huodong-huodongList-stop')" @click="handelStopClick(scope.row)">暂停</el-button>
							<el-button type="text" size="small" v-if="scope.row.activityStatus != 2"
								@click="handelDeleteClick(scope.row)">删除</el-button>
							<el-button type="text" size="small" v-if="$buttonAuthority('huodong-huodongList-see')"
								@click="handelEditClick(scope.row, 1)">查看</el-button>
							<el-button type="text" size="small" 
								v-if="scope.row.activityStatus != 2&&$buttonAuthority('huodong-huodongList-edit')" @click="handelEditClick(scope.row, 2)">编辑</el-button>
							<el-button type="text" size="small" 
								v-if="scope.row.activityStatus == 4&&$buttonAuthority('huodong-huodongList-stop')" @click="handelStartClick(scope.row)">启动</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		</div>
	</div>
</template>

<script>
import page from "../../components/page.vue"
export default {
	components: {
		page
	},
	data() {
		return {
			page: { //分页
				pageIndex: 1,
				pageSize: 10,
				total: 0,
			},
			pageSizes: [10, 15,30,60],
			tableData: [],
			keyword: '',
			dateValue: [],  //日期
			statusValue: -1, //活动状态值
			statuss: [{ //活动状态列表
				label: '全部',
				value: -1
			}, {
				label: '未进行',
				value: 1
			}, {
				label: '进行中',
				value: 2
			}, {
				label: '已结束',
				value: 3
			}, {
				label: '已停用',
				value: 4
			}],
		}
	},
	mounted() {
		this.getTableData()
	},
	methods: {
		//编辑与查看
		handelEditClick(row, type) {
			let params = {
				id: row.id
			}
			this.$http.get("/cms/ActivityInfo/GetActivityInfo", params).then(res => {
				if (res.code === 0) {
					let query = {
						type: type,
						activitInfo: JSON.stringify(res.data)
					}
					this.$router.push({ path: 'huodongDetail', query })
				}
			})
		},
		//删除
		handelDeleteClick(row) {
			this.$confirm('是否删除该活动？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = {
					id: row.id,
				}
				this.$http.get("/cms/ActivityInfo/DeleteInfo", params).then(res => {
					if (res.code === 0) {
						this.$message({
							type: 'info',
							message: '已删除'
						})
						this.getTableData()
					}
				})
			})
		},
		//启动活动
		handelStartClick(row) {
			this.$confirm('是否启动该活动？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = {
					id: row.id,
				}
				this.$http.get("/cms/ActivityInfo/StartInfo", params).then(res => {
					if (res.code === 0) {
						this.$message({
							type: 'success',
							message: '已启动'
						})
						this.getTableData()
					}
				})
			})
		},
		//暂停
		handelStopClick(row) {
			this.$confirm('是否暂停该活动？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = {
					id: row.id,
				}
				this.$http.get("/cms/ActivityInfo/StopInfo", params).then(res => {
					if (res.code === 0) {
						this.$message({
							type: 'info',
							message: '已暂停'
						})
						this.getTableData()
					}
				})
			})
		},
		// 新建活动
		handleAddClick() {
			this.$router.push({ path: 'huodongDetail' })
		},
		//搜索
		handelSearchClick() {
			if (this.dateValue == null) {
				this.dateValue = []
			}
			this.page.pageIndex = 1;
			this.getTableData()
		},
		//切换分页
		changePage(num) {
			this.page.pageIndex = num
			this.getTableData();
		},
		handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
		//表格
		getTableData() {
			let params = {
				pageIndex: this.page.pageIndex,
				pageSize: this.page.pageSize,
				keyword: this.keyword,
				beginTime: new Date(this.dateValue[0]).getTime() / 1000,
				endTime: new Date(this.dateValue[1]).getTime() / 1000,
				activityStatus: this.statusValue
			}
			this.$http.post("/cms/ActivityInfo/GetActivityInfoList", params).then(res => {
				if (res.code === 0) {
					this.tableData = res.data.list;
					this.page.total = res.data.count;
				}
			})
		},
		//重置
		handleResetClick() {
			this.keyword = '';
			this.dateValue = []; 
			this.statusValue = -1;
			this.page.pageIndex = 1;
			this.getTableData()
		}
	}
}
</script>

<style lang="scss" scoped>
.page {
	width: 100%;
	height: 100%;
	color: $fontColor;
	display: flex;
	flex-direction: column;

	.searchView {
		height: 80px;
		border-radius: 20px;
		margin-bottom: 16px;
		background: #fff;
		display: flex;
		align-items: center;
		padding: 0 20px;

		.search {
			display: flex;
			align-items: center;
			margin-right: 32px;

			.searchTitle {
				font-size: 14px;
				color: $fontColor;
			}
		}

		.btnView {
			flex: 1;
			text-align: right;
		}
	}

	.view {
		border-radius: 20px;
		overflow: hidden;
		flex: 1;
		background: #fff;
		display: flex;
		flex-direction: column;

		.tableView {
			flex: 1;
		}
	}
}
</style>
